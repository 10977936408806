<template>
    <div class="page">
        <el-row class="query-form p_b1">
            <el-col :span="16">
                <el-select v-model="searchForm.blackState" placeholder="状态" size="small" style="width: 20%">
                    <el-option
                            v-for="item in blackStateOpt"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
                <el-select v-model="searchForm.ticketingCardTypeId" placeholder="证件类型" size="small" class="m_l1 m_r1"
                           style="width: 20%" clearable>
                    <el-option
                            v-for="item in cardTypeOpt"
                            :key="item.id"
                            :label="item.cardTypeName"
                            :value="item.id">
                    </el-option>
                </el-select>
                <el-input size="small" v-model="searchForm.keyWords" placeholder="请输入参观人证件号码/姓名" maxlength="50" class="m_r1"
                          style="width: 30%" clearable></el-input>
                <el-button type="primary" @click="queryData(1)" size="small" icon="el-icon-search">查询
                </el-button>
                <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
            </el-col>
            <el-col :span="8" class="text_right">
                <el-button type="primary" v-if="hasPermission('blacklist:addTime')"
                           :disabled="dataListSelections.length <= 0" @click="addTime()" size="small">加时长
                </el-button>
                <el-button type="primary" v-if="hasPermission('blacklist:release')"
                           :disabled="dataListSelections.length <= 0" @click="release()" size="small">释放
                </el-button>
                <el-button type="primary" v-if="hasPermission('blacklist:set')" @click="blackSetting()" size="small">
                    拉黑设置
                </el-button>
            </el-col>
        </el-row>
        <div class="bg-white">
            <el-table :data="list"
                      v-loading="loading"
                      @selection-change="selectionChangeHandle"
                      size="small"
                      height="calc(100vh - 280px)" class="table">
                <el-table-column type="selection" width="50" fixed :selectable="checkSelectable"></el-table-column>
                <el-table-column prop="ticketingVisitorUser" label="姓名" show-overflow-tooltip></el-table-column>
                <el-table-column prop="ticketingCardType" label="证件类型" show-overflow-tooltip></el-table-column>
                <el-table-column prop="ticketingVisitorCard" label="证件号" show-overflow-tooltip></el-table-column>
                <el-table-column prop="blackState" label="状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        已{{scope.row.blackState == 0 ? '拉黑':'释放'}}
                    </template>
                </el-table-column>
                <el-table-column prop="pullBlackTime" label="拉黑时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="blackDay" label="禁止时长" show-overflow-tooltip></el-table-column>
                <el-table-column prop="releaseBlackTime" label="释放时间" show-overflow-tooltip></el-table-column>
                <el-table-column fixed="right" width="150" label="操作">
                    <template slot-scope="scope">
                        <el-button v-if="hasPermission('blacklist:addTime') && scope.row.blackState == 0" type="text" size="mini"
                                   @click="addTime([scope.row.id])">加时长
                        </el-button>
                        <el-button v-if="hasPermission('blacklist:release') && scope.row.blackState == 0" type="text" size="mini"
                                   @click="release([scope.row.id])">释放
                        </el-button>
                        <el-button v-if="hasPermission('blacklist:view')" type="text" size="mini"
                                   @click="view(scope.row)">详情
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="searchForm.current"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="searchForm.size"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <!--加时长-->
        <el-dialog title="加时长" width="45%" :close-on-click-modal="false" :visible.sync="addTimeVisible">
            <el-form size="small" :model="addTimeForm" ref="addTimeForm" label-width="100px">
                <el-form-item label="禁止天数：" prop="blackDay"
                              :rules="[{ required: true, message: '请选择禁止天数', trigger: 'blur' }]">
                    <el-select v-model="addTimeForm.blackDay" placeholder="请选择禁止天数" class="m_r1"
                               @change="changeBlackDay">
                        <el-option
                                v-for="item in blackDayOpt"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                    <el-input v-if="addTimeForm.blackDay == 0" size="small" v-model="customBlackDay"
                              oninput="if(value<1)value=1" onkeyup="value=value.replace(/[^\d]/g,'')" @blur="customBlackDayChange" maxlength="3"
                              placeholder="请输入禁止天数" style="width: 25%"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="addTimeVisible = false">关闭</el-button>
                <el-button size="small" type="primary" @click="confirmAddTime()" v-noMoreClick>确定</el-button>
            </span>
        </el-dialog>
        <!--拉黑设置-->
        <BlackSetting ref="blackSetting"></BlackSetting>
        <!--拉黑详情-->
        <BlackDetails ref="blackDetails"></BlackDetails>
    </div>
</template>

<script>
    import BlackSetting from './blackSetting'
    import BlackDetails from './blackDetails'

    export default {
        components: {BlackSetting, BlackDetails},
        data() {
            return {
                searchForm: {
                    blackState: null,
                    ticketingCardTypeId: null,
                    keyWords: '',
                    current: 1,
                    size: 10,
                },
                blackStateOpt: [
                    {
                        label: '全部',
                        value: null
                    },
                    {
                        label: '已拉黑',
                        value: '0'
                    },
                    {
                        label: '已释放',
                        value: '1'
                    }
                ],
                cardTypeOpt: [],
                list: [],
                total: 0,
                loading: false,
                dataListSelections: [],
                // 加时长
                addTimeVisible: false,
                addTimeForm: {
                    blackDay: null,
                    id: [],
                },
                customBlackDay: null,
                blackDayOpt: [
                    {
                        label: '3天',
                        value: 3
                    },
                    {
                        label: '7天',
                        value: 7
                    },
                    {
                        label: '自定义',
                        value: 0
                    }
                ],
            }
        },
        mounted() {
            this.getCardType()
            this.queryData(1)
        },
        methods: {
            // 获取数据
            queryData(type) {
                if (type == 1) {
                    this.searchForm.current = 1
                }
                this.loading = true
                this.$axios(this.api.ticketing.sysGetTicketingBlack, this.searchForm, 'post').then((res) => {
                    if (res.status) {
                        this.list = res.data.records
                        this.total = parseInt(res.data.total)
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.loading = false
                })
            },
            // 重置
            resetSearch() {
                this.searchForm = {
                    blackState: null,
                    ticketingCardTypeId: null,
                    keyWords: '',
                    current: 1,
                    size: 10,
                }
                this.queryData(1)
            },
            // 禁止天数改变
            customBlackDayChange(e) {
                this.customBlackDay = e.target.value
            },
            // 加时长
            addTime(id) {
                this.addTimeForm.blackDay = null
                this.addTimeForm.id = []
                this.customBlackDay = null
                this.addTimeForm.id = id || this.dataListSelections.map(item => {
                    return item.id
                })
                this.addTimeVisible = true
            },
            // 确定加时长
            confirmAddTime() {
                let {blackDay} = this.addTimeForm
                if (blackDay == 0 && this.customBlackDay == null || this.customBlackDay == '') {
                    this.$message('请输入禁止天数');
                    return false;
                }
                let param = JSON.parse(JSON.stringify(this.addTimeForm))
                if (blackDay == 0 && this.customBlackDay) {
                    param.blackDay = parseInt(this.customBlackDay)
                }
                this.$refs['addTimeForm'].validate((valid) => {
                    if (valid) {
                        this.$axios(this.api.ticketing.addBlackDay, param, 'post').then((res) => {
                            if (res.status) {
                                this.$message.success('操作成功');
                                this.addTimeVisible = false
                                this.queryData()
                            } else {
                                this.$message.error(res.msg);
                            }
                        })
                    }
                })
            },
            // 加时长天数改变
            changeBlackDay() {
                this.customBlackDay = null
            },
            // 释放
            release(id) {
                let ids = id || this.dataListSelections.map(item => {
                    return item.id
                })
                const h = this.$createElement;
                this.$msgbox({
                    title: '提示',
                    message: h('p', null, [
                        h('p', null, '确定释放该账号吗？ '),
                        h('p', {style: 'fontSize: 12px'}, '释放后可正常预约线下展览')
                    ]),
                    showCancelButton: true,
                    confirmButtonText: '提交',
                    cancelButtonText: '取消',
                }).then(() => {
                    this.loading = true
                    this.$axios(this.api.ticketing.releaseTicketingBlack, ids, 'post').then(data => {
                        this.loading = false
                        if (data && data.status) {
                            this.$message.success('释放成功');
                            this.queryData()
                        } else {
                            this.$message.error(res.msg);
                        }
                    })
                })
            },
            // 拉黑设置
            blackSetting() {
                this.$refs.blackSetting.init()
            },
            // 详情
            view(row) {
                this.$refs.blackDetails.init(row)
            },
            // 获取证件类型
            getCardType() {
                this.$axios(this.api.allCommon.list, {
                    current: 1,
                    size: 999,
                    cardName: '',
                }, 'get').then((res) => {
                    if (res.status) {
                        this.cardTypeOpt = res.data.records;
                    }
                })
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.queryData()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.queryData()
            },
            // 禁选
            checkSelectable(row) {
                return row.blackState == 0
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
        }
    }
</script>

<style scoped>

</style>
